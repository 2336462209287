import { onMounted, Ref } from 'vue';

const CHECK_INTERVAL = 50;

// Workaround for https://github.com/vuejs/core/issues/5844, found at:
// https://github.com/nuxt/nuxt/issues/13471#issuecomment-1449898517
export default function useSafeOnMounted(element: Ref<HTMLElement | undefined>, listener: () => void, checkTimeLimit: number = 500) {
  if (process.client) {
    const checkInterval = CHECK_INTERVAL;
    let checksLeft = Math.floor(checkTimeLimit / checkInterval);

    const check = () => {
      if (element.value?.isConnected) {
        listener();
      } else if (checksLeft > 0) {
        // Recall in a set interval again until we have no tries left
        setTimeout(check, checkInterval);
        checksLeft--;
      } else {
        // We have no tries left
        console.warn('Element not found in DOM');
      }
    };

    onMounted(() => {
      check();
    });
  }
}
