<template>
  <div class="hazy_img" :style="`background-image: url(${placeholder});`" ref="holder">
    <nuxt-img v-bind="$attrs" @load="onLoaded" loading="lazy"/>
  </div>
</template>

<script lang="ts" setup>
defineOptions({
  inheritAttrs: false
});

defineProps({
  placeholder : {
    type: String as PropType<string>,
    default: ''
  }
});

const holder = ref<HTMLDivElement>();

function onLoaded() {
  holder.value?.classList.add('loaded');
}

useSafeOnMounted(holder, () => {
  // We do a manual call as the nuxt-img component does not expose
  // the native image element in order to get the complete property
  const image = holder.value?.querySelector('img');

  if (image?.complete) {
    onLoaded();
  }
});
</script>

<style lang="scss">
.hazy_img {
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  img {
    opacity: 0;
    transition: opacity 250ms ease-in-out;
  }

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    opacity: 0;
    animation: pulse 2.5s infinite;
    background-color: $background;
  }

  &.loaded {
    &::before {
      animation: none;
      content: '';
    }

    img {
      opacity: 1;
    }
  }
}

@keyframes pulse {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 0;
  }
}
</style>

